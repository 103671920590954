<template>
  <div>
    <el-container>
      <el-header class="DetailsHeader">
            <div class="IndexTab" @click="ToIndex">首页</div>
            <div class="ZoneTab" @click="ToZone">广场</div>
            <div class="Avatar">
                <img :src="userinfo.icon">
                <div class="InfoPanel">
                    <div class="Username">
                        {{userinfo.nickname}}
                    </div>
                    <div class="InfoBanner">
                        <div class="UserCenter" @click="ToUserCenter">
                            <i class="el-icon-user-solid mark"></i>个人中心
                        </div>
                        <div class="ContentMP" @click="ToMp">
                            <i class="el-icon-s-management mark"></i>内容管理
                        </div>
                    </div>
                </div>
            </div>
            <div class="PublishBlog" @click="PublishBlog">
                <i class="el-icon-edit"></i>发布
            </div>
        </el-header>
      <el-main style="padding:0">
        <div class="Banner">
          <div class="BannerTitle">
            <div class="BannerTitleLeft">
              <img :src="userinfo.icon">
            </div>
            <div class="BannerTitleRight">
              <div class="RightAreaUsername">{{userinfo.nickname}}</div>
            </div>
          </div>
          <div class="BannerBody">
            <div class="BannerBodyLeft">
              <div class="Achievement">
                <div class="AchievementTitle">个人成就</div>
                <div class="AchievementContent">
                  <div class="DianZan">
                    <i class="iconfont icon-dianzan1" style="padding:0px 8px 0px 0px"></i>
                    <span>获得0次点赞</span>
                  </div>
                  <div class="PingLun">
                    <i class="el-icon-s-comment" style="padding:0px 8px 0px 0px"></i>
                    <span>内容获得0次评论</span>
                  </div>
                </div> 
              </div>
              <div class="Interest">
                <div class="InterestTitle">兴趣领域</div>
                <div class="InterestContent">
                  <div class="InterestName">文学</div>
                  <div class="InterestDetails">
                    <i class="el-icon-price-tag"></i>
                    <span class="Tags">
                      辛德勒的名单
                    </span>
                    <span class="Tags">
                      雨中曲
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div class="BannerBodyRight">
              <div class="FirstType">
                <div class="ArticleIndex" @click="ToArticleIndex" id="index"  :class="activeClass == 'index' ? 'active':''">首页</div>
                <div class="ArticleQueAns" @click="ToArticleQueAns" id="queans"  :class="activeClass == 'queans' ? 'active':''">问答</div>
              </div>
              <router-view></router-view>
            </div>
          </div>
        </div>
      </el-main>
    </el-container>
  </div>
</template>

<script>
import { GetUserNickName } from '../api/user'
export default {
    name:'MySpace',
    data(){
      return{
        userinfo:'',
        activeClass:'index'
      }
    },
    created(){
      this.UserInfo()
    },
    mounted(){
      
    },
    methods:{
      async UserInfo(){
          let res = await GetUserNickName()
          this.userinfo = res.data
      },
      ToIndex(){
            this.$router.push({path:'/'})
      },
      ToUserCenter(){
          this.$router.push({path:'/account/usercenter'})
      },
      ToMp(){
          this.$router.push({path:'/mp_blog'})
      },
      PublishBlog(){
          this.$router.push({path:'/md_article'})
      },
      ToZone(){
          this.$router.push({path:'/zone'})
      },
      ToArticleIndex(e){
        let initid = e.currentTarget.id
        this.activeClass = initid
        this.$router.push({path:'/myspace/article'})
      },
      ToArticleQueAns(e){
        let initid = e.currentTarget.id
        this.activeClass = initid
        this.$router.push({path:'/myspace/queans'})
      }
    }

}
</script>

<style scoped>
.DetailsHeader{
    position: sticky;
    top: 0;
    height: 40px !important;
    width: 100%;
    background-color: rgb(255, 255, 255);
    box-shadow: 0px 5px 50px 0px rgb(0 0 0 / 10%);
    display: flex;
    align-items: center;
    z-index: 50;
}
.IndexTab,.ZoneTab{
    height: 75%;
    width: 60px;
    font-size: 14px;
    line-height: 30px;
    cursor: pointer;
}
.IndexTab:hover,.ZoneTab:hover{
    background-color: #dddddd;
}
.Avatar{
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    top: 0;
    right: 130px;
    width: 40px;
    height: 100%;
    border-radius: 20px;
    cursor: pointer;
}
.Avatar img{
    position: absolute;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 20px;
}
.Avatar:hover img{
    margin-top: 10px;
    transform:scale(1.5);
    z-index: 20;
}
.Avatar:hover .InfoPanel{
    display: block;
}
.InfoPanel{
    position: absolute;
    display: none;
    width: 200px;
    top: 40px;
    height: 300px;
    padding: 20px 0px 0px 0px;
    border-radius: 4px;
    background-color: white;
    box-shadow: 0px 5px 50px 0px rgb(0 0 0 / 10%);
}
.Username{
    width: 100%;
    height: 20px;
    padding: 10px 0px 20px 0px;
    font-size: 15px;
    font-weight: 600;
    border-bottom: 1px solid #e8e8ed;
    font-family: 'PingFang SC','SF Pro Display','Microsoft YaHei',Roboto,Noto,Arial,sans-serif;
}
.InfoBanner{
    padding: 5px 0px 0px 0px ;
}
.UserCenter,.ContentMP{
    padding: 5px 0px 0px 10px;
    height: 30px;
    line-height: 30px;
    font-size: 15px;
    text-align: left;
    color: gray;
}
.mark{
    padding: 0px 8px 0px 0px;
}
.UserCenter:hover,.ContentMP:hover{
    background-color: #dddddd;
}
.PublishBlog{
    font-size: 14px;
    position: absolute;
    right: 20px;
    top: 4px;
    width: 60px;
    height: 80%;
    line-height: 32px;
    border-radius: 20px;
    color: white;
    cursor: pointer;
    background-color: #fc5531;
}
.Banner{
  height: auto;
  padding: 100px;
  /* background-color: #f2f4f5; */
}
.BannerTitle{
  height: 150px;
  display: flex;
  padding: 0px 0px 0px 10px;
  border-radius: 4px;
  background-color: white;
}
.BannerTitleLeft{
  position: relative;
  width: 100px;
}
.BannerTitleLeft img{
  position: absolute;
  top: -10px;
  left: 0;
  width: 100%;
  border-radius: 50px;
}
.BannerTitleRight{
  flex: 1;
  border-radius: 4px;
  text-align: left;
  padding: 10px 0px 5px 10px;
}
.RightAreaUsername{
  font-size: 20px;
  font-weight: 600;
  color: #222226;
}
.BannerBody{
  display: flex;
  margin-top: 10px;
  border-radius: 4px;
  /* background-color: white; */
  align-items: flex-start;
}
.BannerBodyLeft{
  flex: 0 0 30%;
  height: auto;
  border-radius: 4px;
  /* background-color: #c6c6c6; */
}
.Achievement{
  height: auto;
}
.Interest{
  margin-top: 10px;
}
.AchievementTitle{
  text-align: left;
  font-size: 16px;
  height: 40px;
  padding:5px 0px 5px 10px;
  line-height: 40px;
  border-radius: 2px;
  color: #222226;
  background-color: white;
}
.AchievementContent{
  margin-top: 1px;
  padding:5px 0px 5px 10px;
  border-radius: 2px;
  background-color: white;
}
.DianZan,.PingLun{
  padding: 5px 0px;
  font-size: 14px;
  height: 30px;
  text-align: left;
  line-height: 30px;
  letter-spacing: 1.5px;
  color: #676767;
}
.InterestTitle{
  font-size: 16px;
  text-align: left;
  height: 40px;
  padding:5px 0px 5px 10px;
  line-height: 40px;
  border-radius: 2px;
  background-color: white;
}
.InterestContent{
  margin-top: 1px;
  padding:5px 0px 5px 10px;
  border-radius: 2px;
  background-color: white;
}
.InterestName{
  padding: 5px 0px;
  font-size: 16px;
  text-align: left;
  letter-spacing: 1.5px;
  color: #676767;
}
.InterestDetails{
  padding: 5px 0px;
  font-size: 13px;
  text-align: left;
  letter-spacing: 1.5px;
  color: #676767;
  display: flex;
  align-items: center;
}
.Tags{
  margin-right: 5px;
}
.BannerBodyRight{
  flex: 1;
  margin-left: 10px;
  height: auto;
  border-radius: 4px;
  background-color: white;
}
.FirstType{
  display: flex;
  height: 40px;
  width: 100%;
  align-items: center;
}
.ArticleIndex{
  font-size: 14px;
  height: 100%;
  width: 50px;
  padding: 0 5px;
  font-weight: 500;
  line-height: 40px;
  cursor: pointer;
}
.ArticleQueAns{
  font-size: 14px;
  height: 100%;
  width: 50px;
  padding: 0 5px;
  font-weight: 500;
  line-height: 40px;
  cursor: pointer;
}
.active{
  font-weight: 600;
}
</style>